<template>
  <v-container
    class="fill-height"
    fluid
    >
    <v-container fill-height fluid>
      <v-row align="left"
        justify="left">
        <v-card v-if="user" class="ma-2" color="#E84B4F">
          <v-card-title>Orphan Deployments</v-card-title>
          <v-card-subtitle>Deployments that are active in the infrastructure but should have been cleaned up.</v-card-subtitle>
          <v-data-table
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDescending"
            :headers="headers"
            :items="orphan_deployments"
            :items-per-page="10"
            class="elevation-1"
            :footer-props="{
            showFirstLastPage: true
            }"
            >
            <template #item.id="{ item }">
              <router-link :to="{ name: 'Deployment', params: { deploymentId: item.id }}">{{ item.id }}</router-link>
            </template>
            <template #item.jira_url="{ item }">
              <a v-if="item.jira_url != ''" target="_blank" :href="item.jira_url">
              {{ item.jira_url | jiraID }}
              </a>
              <span v-else>-</span>
            </template>
            <template #item.expired_at="{ item }">
              {{ item.expired_at | moment('from', 'now')}}
            </template>
            <template #item.hosts="{ item }">
              <span v-if="item.hosts != ''">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <v-icon>mdi-open-in-new</v-icon>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-list flat>
                        <v-list-item-group>
                          <v-list-item v-for="url in item.hosts" v-bind:key="url">
                            <a style="text-decoration: none;" target="_blank" :href="url | setHttp" :title="url | setHttp">
                            {{ url }}
                            </a>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </span>
              <span v-else>-</span>
            </template>
            <template #item.log_file="{ item }">
              <a v-if="item.log_file != ''" style="text-decoration: none;" target="_blank" :href="'/media/deployments/log/' + item.log_file">
                <v-icon>
                  mdi-tray-arrow-down
                </v-icon>
              </a>
              <span v-else>-</span>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      sortBy: 'id',
      sortDescending: true,
      headers: [{
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Namespace',
          value: 'namespace',
        },
        {
          text: 'Jira',
          value: 'jira_url',
        },
        {
          text: 'State',
          value: 'state',
        },
        {
          text: 'Profile',
          value: 'profile',
        },
        {
          text: 'User',
          value: 'user',
        },
        {
          text: 'Hosts',
          value: 'hosts',
        },
        {
          text: 'Logs',
          value: 'log_file',
        },
        {
          text: 'Expiration',
          value: 'expired_at',
        },
      ],
      orphan_deployments: [],
      errors: []
    }
  },
  created() {
    axios.get(`/api/v1/deployments/orphan`)
      .then(response => {
        this.orphan_deployments = response.data.data
      })
      .catch(e => {
        this.errors.push(e)
      })
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  mounted() {
    this.$store.dispatch("getUser");
  }
}
</script>

<style>
  .theme--dark.v-expansion-panels .v-expansion-panel {
    background-color: transparent;
  }
  .theme--dark.v-list {
    background-color: transparent;
  }
  .v-expansion-panel:before {
    box-shadow: none;
  }
</style>
