import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'

Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV === 'development'

// Allow moment filters
Vue.use(VueMoment, {
  moment,
})

// Concatenate the user attributes.
Vue.filter('displayName', function(user) {
  if (user.first_name !== undefined) {
    return (user.support ? '(Staff) ' : '') + '(' + user.user + ') ' + user.first_name + ' ' + user.last_name
  }
  return ''
})
// Extracts the Jira ticket ID.
Vue.filter('jiraID', function(jira_url) {
  if (jira_url !== '') {
    return jira_url.substring(jira_url.lastIndexOf('/') + 1) // Everything after last slash.
  }
  return '-'
})
// Moment local from UTC.
Vue.filter('localFromUTC', function(dt) {
  return moment.utc(dt).local().format('YYYY-MM-DDTHH:mm:ssZ')
})
// Add scheme to URL.
Vue.filter('setHttp', function(url) {
  if (url.search(/^http[s]\?:\/\//) == -1) {
    url = 'http://' + url
  }
  return url
})
// Prom stats formatting.
Vue.filter('promFormat', function(float) {
  return parseInt(100 * float)
})
// Build the opensearch URL.
Vue.filter('openSearchURL', function(namespace, opensearch_index, created_at, expired_at) {
  // special flake.
  if (namespace == 'promoted') {
    expired_at = moment(expired_at).add(20, 'days').format('YYYY-MM-DDTHH:mm:ssZ')
  }
  return "https://dashboards.opensearch.eencloud.com/app/discover?security_tenant=global#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:'" + created_at + "',to:'" + expired_at + "'))&_a=(columns:!(pod,kubernetes_container_name,log),filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'" + opensearch_index + "',key:pod,negate:!f,params:(query:test),type:phrase),query:(match_phrase:(pod:test))),('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'" + opensearch_index + "',key:kubernetes_namespace_name,negate:!f,params:(query:" + namespace + "),type:phrase),query:(match_phrase:(kubernetes_namespace_name:" + namespace + ")))),index:'" + opensearch_index + "',interval:auto,query:(language:kuery,query:''),sort:!())"
})

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
