<template>
  <v-container
    class="fill-height"
    fluid
    >
    <v-card v-if="user" class="ma-2" color="#2B2D2F">
      <v-card-title>User Secrets</v-card-title>
      <v-card-subtitle>Below secrets are required for deploying in the infrastructure.</v-card-subtitle>
      <v-form
        ref="form"
        v-model="valid"
        class="ma-2 px-10 pb-10"
        value
        >
        <v-text-field
          v-model="vault"
          :append-icon="show_vault ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show_vault ? 'text' : 'password'"
          :autocomplete="show_vault ? 'text' : 'off'"
          :counter="40"
          :rules="vaultRules"
          label="Vault token"
          required
          @click:append="show_vault = !show_vault"
          ></v-text-field>
        <v-text-field
          v-model="github"
          :append-icon="show_github ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show_github ? 'text' : 'password'"
          :autocomplete="show_vault ? 'text' : 'off'"
          :counter="40"
          :rules="githubRules"
          label="GitHub personal access token"
          required
          @click:append="show_github = !show_github"
          ></v-text-field>
        <br/>
        <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="post_secrets"
          >
          Update
        </v-btn>
        <v-btn
          color="error"
          class="mr-4"
          @click="reset"
          >
          Reset Form
        </v-btn>
      </v-form>
      <v-alert
        dense
        text
        type="success"
        v-if="alert"
        >
        {{alert}}
      </v-alert>
    </v-card>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  mounted() {
    this.$store.dispatch("getUser");
  },
  data: () => ({
    valid: false,
    alert: false,
    vault: '',
    github: '',
    show_vault: false,
    show_github: false,
    vaultRules: [
      v => !!v || 'Vault token is required',
      v => (v && v.length == 40) || 'Vault token must be exactly 40 characters',
    ],
    githubRules: [
      v => !!v || 'GitHub personal access token token is required',
      v => (v && v.length == 40) || 'GitHub personal access token must be exactly 40 characters',
    ]
  }),
  methods: {
    post_secrets() {
      var self = this
      axios.post('/api/v1/user/secrets', {
          vault: this.vault,
          github: this.github
        }).then(function(response) {
          self.alert = 'Your secrets have been successfully submitted'
          setTimeout(() => self.alert = false, 3000);
          console.log(response.data.data);
        })
        .catch(error => {
          console.error("There was an error!", error);
        });
    },
    reset() {
      this.$refs.form.reset()
    }
  },
}
</script>
