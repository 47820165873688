<template>
  <v-container
    class="fill-height"
    fluid
    >
    <v-card v-if="user">
      <v-card-title>Deployment Details</v-card-title>
      <v-card-subtitle>Detailed information about deployment #{{deployment.id}}.</v-card-subtitle>
      <v-simple-table dense>
        <template v-slot:default>
          <tbody>
            <tr>
              <td class="font-weight-bold">ID</td>
              <td>{{ deployment.id }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">Jira URL</td>
              <td><a v-if="deployment.jira_url != ''" target="_blank" :href="deployment.jira_url">{{ deployment.jira_url | jiraID }}</a><span v-else>-</span></td>
            </tr>
            <tr>
              <td class="font-weight-bold">Namespace</td>
              <td>{{ deployment.namespace }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">State</td>
              <td>{{ deployment.state }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">Profile</td>
              <td>{{ deployment.profile }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">User</td>
              <td>{{ deployment.user }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">Last Actor</td>
              <td>{{ deployment.last_actor }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">Git Branches</td>
              <td>{{ deployment.git_branches }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">Hosts</td>
              <td>
                <span v-if="deployment.hosts != ''">
                  <span v-for="url in deployment.hosts" v-bind:key="url">
                    <a style="text-decoration: none;" target="_blank" :href="url | setHttp" :title="url | setHttp">
                      <v-icon>
                        mdi-open-in-new
                      </v-icon>
                    </a>
                  </span>
                </span>
                <span v-else>-</span>
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold">Overrides</td>
              <td>{{ deployment.overrides }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">Created At</td>
              <td>{{ deployment.created_at }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">Expired At</td>
              <td>{{ deployment.expired_at }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">Destroyed At</td>
              <td>{{ deployment.destroyed_at }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            deployment: null,
            errors: []
        }
    },
    created() {
        axios.get(`/api/v1/deployment/` + this.$route.params.deploymentId)
            .then(response => {
                this.deployment = response.data.data
            })
            .catch(e => {
                this.errors.push(e)
            })
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    mounted() {
        this.$store.dispatch("getUser");
    }
}
</script>
