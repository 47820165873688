import axios from 'axios'
import Vuex from 'vuex'
import Vue from 'vue'

//load Vuex
Vue.use(Vuex);

//to handle state
const state = {
  user: null,
  stats: null
}

//to handle state
const getters = {}

//to handle actions
const actions = {
  getUser({
    commit
  }) {
    axios.get('/api/v1/user')
      .then(response => {
        commit('SET_USER', response.data.data)
      })
  },
  getStats({
    commit
  }) {
    axios.get('/api/v1/stats')
      .then(response => {
        commit('SET_STATS', response.data.data)
      })
  }
}

//to handle mutations
const mutations = {
  SET_USER(state, user) {
    state.user = user
  },
  SET_STATS(state, stats) {
    state.stats = stats
  }
}

//export store module
export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations
})
