<template>
  <v-container
    class="fill-height"
    fluid
    >
    <v-container fill-height fluid>
      <v-row align="center"
        justify="center">
        <v-col>
          <v-alert
            dense
            text
            type="success"
            v-if="alert"
            >
            {{alert}}
          </v-alert>
          <v-alert
            dense
            text
            type="error"
            v-if="error_alert"
            >
            {{error_alert}}
          </v-alert>
          <v-card v-if="user" class="ma-2" color="#228B22">
            <v-card-title>Active Deployments</v-card-title>
            <v-card-subtitle>Deployments currently existing in the test environment.</v-card-subtitle>
            <v-data-table
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDescending"
              :headers="active_headers"
              :items="active_deployments"
              :items-per-page="10"
              class="elevation-1"
              :footer-props="{
              showFirstLastPage: true
              }"
              >
              <template #item.is_promoted="{ item }">
                <v-btn v-if="item.is_promoted == 0 && (item.state == 'DEPLOYED' || item.state == 'FAILURE')" icon @click="() => post_promote(item.id)">
                  <v-icon dark title="Promote">mdi-stairs-up</v-icon>
                </v-btn>
                <v-btn v-if="item.is_promoted == 1 && item.state == 'DEPLOYED'" icon>
                  <v-icon dark title="Promoted" color="lime accent-2">mdi-stairs-up</v-icon>
                </v-btn>
              </template>
              <template #item.id="{ item }">
                <router-link :to="{ name: 'Deployment', params: { deploymentId: item.id }}">{{ item.id }}</router-link>
              </template>
              <template #item.jira_url="{ item }">
                <a v-if="item.jira_url != ''" target="_blank" :href="item.jira_url">
                {{ item.jira_url | jiraID }}
                </a>
                <span v-else>-</span>
              </template>
              <template #item.state="{ item }">
                <v-progress-linear
                  indeterminate
                  color="yellow darken-2" v-if="item.state == 'RECEIVED'" 
                  ></v-progress-linear>
                <span v-else-if="item.state === 'FAILURE'">
                  <a href="https://eagleeyenetworks.atlassian.net/wiki/spaces/ENG/pages/2721644588/Troubleshooting+Failed+Asmodeus+Deployments" target="_blank" class="primary--text">{{ item.state }}</a>
                </span>
                <span v-else>{{ item.state }}</span>
              </template>
              <template #item.expired_at="{ item }">
                {{ item.expired_at | moment('from', 'now')}}
              </template>
              <template #item.hosts="{ item }">
                <span v-if="item.hosts != ''">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <v-icon>mdi-open-in-new</v-icon>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-list flat>
                          <v-list-item-group>
                            <v-list-item v-for="url in item.hosts" v-bind:key="url">
                              <a v-if="item.state == 'DEPLOYED' && !url.includes('promoted')" style="text-decoration: none;" target="_blank" :href="url | setHttp" :title="url | setHttp">
                              {{ url }}
                              </a>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </span>
                <span v-else>-</span>
              </template>
              <template #item.log_file="{ item }">
                <v-btn v-if="item.log_file != '' && item.is_promoted == 0" icon title="Download Logs" :href="'/media/deployments/log/' + item.log_file" target="_blank">
                  <v-icon color="white">mdi-wrench</v-icon>
                </v-btn>
                <v-btn v-if="item.opensearch_index != '' && item.created_at != '' && item.is_promoted == 0" :href="item.namespace | openSearchURL(item.opensearch_index, item.created_at, item.expired_at)" icon title="OpenSearch Logs" target="_blank">
                  <v-icon color="teal">mdi-format-align-justify</v-icon>
                </v-btn>
              </template>
              <template slot="item.redeploy" slot-scope="props">
                <v-btn v-if="props.item.state == 'DEPLOYED' || props.item.state == 'FAILURE'" class="mx-2" icon @click="() => post_redeploy(props.item.id)">
                  <v-icon dark title="Redeploy">mdi-refresh</v-icon>
                </v-btn>
                <span v-else>-</span>
              </template>
              <template #item.user="{ item }">
                <span v-if="item.user == user.user">
                <mark>{{ item.user }}</mark>
                </span>
                <span v-else>{{ item.user }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container fill-height fluid>
      <v-row align="center"
        justify="center">
        <v-col>
          <v-card v-if="user" class="ma-2" color="#3D426B">
            <v-card-title>Promoted Deployments</v-card-title>
            <v-card-subtitle>Deployments living in the promoted namespace.</v-card-subtitle>
            <v-data-table
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDescending"
              :headers="promoted_headers"
              :items="promoted_deployments"
              :items-per-page="10"
              class="elevation-1"
              :footer-props="{
              showFirstLastPage: true
              }"
              >
              <template #item.is_promoted="{ item }">
                <v-btn v-if="item.is_promoted == 1" icon @click="() => post_demote(item.id)">
                  <v-icon dark title="Demote" color="red darken-4">mdi-close-thick</v-icon>
                </v-btn>
              </template>
              <template #item.id="{ item }">
                <router-link :to="{ name: 'Deployment', params: { deploymentId: item.id }}">{{ item.id }}</router-link>
              </template>
              <template #item.jira_url="{ item }">
                <a v-if="item.jira_url != ''" target="_blank" :href="item.jira_url">
                {{ item.jira_url | jiraID }}
                </a>
                <span v-else>-</span>
              </template>
              <template #item.log_file="{ item }">
                <v-btn v-if="item.log_file != '' && item.is_promoted == 1" icon title="Download Logs" :href="'/media/deployments/log/' + item.log_file" target="_blank">
                  <v-icon color="white">mdi-wrench</v-icon>
                </v-btn>
                <v-btn v-if="item.opensearch_index != '' && item.created_at != '' && item.is_promoted == 1" :href="'promoted' | openSearchURL(item.opensearch_index, item.created_at, item.expired_at)" icon title="OpenSearch Logs" target="_blank">
                  <v-icon color="teal">mdi-format-align-justify</v-icon>
                </v-btn>
              </template>
              <template #item.expired_at="{ item }">
                {{ item.expired_at | moment('add', '20 days') | moment('from', 'now')}}
              </template>
              <template #item.hosts="{ item }">
                <span v-if="item.hosts != ''">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <v-icon>mdi-open-in-new</v-icon>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-list flat>
                          <v-list-item-group>
                            <v-list-item v-for="url in item.hosts" v-bind:key="url">
                              <a v-if="item.state == 'DEPLOYED' && url.includes('promoted')" style="text-decoration: none;" target="_blank" :href="url | setHttp" :title="url | setHttp">
                              {{ url }}
                              </a>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </span>
                <span v-else>-</span>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      sortBy: 'id',
      sortDescending: true,
      error_alert: false,
      alert: false,
      active_headers: [{
          text: '',
          value: 'is_promoted'
        },
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Namespace',
          value: 'namespace',
        },
        {
          text: 'Jira',
          value: 'jira_url',
        },
        {
          text: 'State',
          value: 'state',
        },
        {
          text: 'Profile',
          value: 'profile',
        },
        {
          text: 'User',
          value: 'user',
        },
        {
          text: 'Hosts',
          value: 'hosts',
        },
        {
          text: 'Logs',
          value: 'log_file',
        },
        {
          text: 'Expiration',
          value: 'expired_at',
        },
        {
          text: '',
          value: 'redeploy'
        },
      ],
      promoted_headers: [{
          text: '',
          value: 'is_promoted'
        },
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Jira',
          value: 'jira_url',
        },
        {
          text: 'Profile',
          value: 'profile',
        },
        {
          text: 'User',
          value: 'user',
        },
        {
          text: 'Hosts',
          value: 'hosts',
        },
        {
          text: 'Logs',
          value: 'log_file',
        },
        {
          text: 'Expiration',
          value: 'expired_at',
        },
      ],
      active_deployments: [],
      promoted_deployments: [],
      errors: []
    }
  },
  created() {
    var self = this
    self.get_active_deployments()
    self.get_promoted_deployments()
  },
  methods: {
    get_active_deployments() {
      axios.get(`/api/v1/deployments/active`)
        .then(response => {
          this.active_deployments = response.data.data
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
    get_promoted_deployments() {
      axios.get(`/api/v1/deployments/promoted`)
        .then(response => {
          this.promoted_deployments = response.data.data
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
    post_redeploy(deployment_id) {
      var self = this
      axios.post('/api/v1/tasks/deploy/redeploy/' + deployment_id).then(function(response) {
          self.alert = 'Redeploying, please allow some time'
          setTimeout(() => self.alert = false, 3000);
          setTimeout(() => self.get_active_deployments(), 5000);
          setTimeout(() => self.get_promoted_deployments(), 5000);
          console.log(response.data.data)
        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
    post_promote(deployment_id) {
      var self = this
      axios.post('/api/v1/tasks/deploy/promote/' + deployment_id).then(function(response) {
          self.alert = 'Promoting, please allow some time'
          setTimeout(() => self.alert = false, 3000);
          setTimeout(() => self.get_active_deployments(), 5000);
          setTimeout(() => self.get_promoted_deployments(), 5000);
          console.log(response.data.data)
        })
        .catch(error => {
          self.error_alert = 'Cannot promote this deployment. Check if the resource already exist'
          setTimeout(() => self.error_alert = false, 3000);
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
    post_demote(deployment_id) {
      var self = this
      axios.post('/api/v1/tasks/deploy/demote/' + deployment_id).then(function(response) {
          self.alert = 'Demoting deployment, please wait'
          setTimeout(() => self.alert = false, 3000);
          setTimeout(() => self.get_active_deployments(), 5000);
          setTimeout(() => self.get_promoted_deployments(), 5000);
          console.log(response.data.data)
        })
        .catch(error => {
          console.error("There was an error!", error);
        });
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  mounted() {
    this.$store.dispatch("getUser");
  }
}
</script>

<style>
  .theme--dark.v-expansion-panels .v-expansion-panel {
    background-color: transparent;
  }
  .theme--dark.v-list {
    background-color: transparent;
  }
  .v-expansion-panel:before {
    box-shadow: none;
  }
</style>
