import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Deployment from '../views/Deployment.vue'
import Maintenance from '../views/Maintenance.vue'
import Settings from '../views/Settings.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance
  },
  {
    path: '/deployment/:deploymentId',
    name: 'Deployment',
    component: Deployment
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/app',
  routes
})

export default router
